<template>
  <div>
    <NavigationBar titel="Self-Service"></NavigationBar>
    <section>
      <template>
        <v-container justify="center" align="center">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <self-service-quick-actions-card></self-service-quick-actions-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <self-service-open-tasks-card></self-service-open-tasks-card>
            </v-col>
          </v-row>
          <v-row class="match-height">
            <v-col cols="12" sm="4" md="4">
              <v-card>
                <v-card-title>Zielvorgaben</v-card-title>
                <v-card-subtitle>Aktueller Bezugszeitraum</v-card-subtitle>
                <!-- <v-card-text> -->
                <v-list class="card-list">
                  <v-list-item
                    v-for="assignment in assignmentData"
                    :key="assignment.title"
                    class="mb-2"
                  >
                    <v-progress-circular
                      v-model="assignment.progress"
                      :size="54"
                      class="me-6"
                      :color="assignment.color"
                    >
                      <span class="font-weight-medium"
                        >{{ assignment.progress }}%</span
                      >
                    </v-progress-circular>

                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-medium mb-2 text-truncate"
                      >
                        {{ assignment.title }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        >{{ assignment.tasks }} von
                        {{ assignment.tasks }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action> </v-list-item-action>
                  </v-list-item>
                </v-list>
                <!-- </v-card-text> -->
              </v-card>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-card>
                <apexchart
                  type="radialBar"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SelfServiceQuickActionsCard from "@/components/self-service/SelfServiceQuickActionsCard.vue";
import SelfServiceOpenTasksCard from "@/components/self-service/SelfServiceOpenTasksCard.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "self-service-portal",
  components: {
    NavigationBar,
    SelfServiceQuickActionsCard,
    SelfServiceOpenTasksCard,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      // List
      assignmentData: [
        {
          title: "Einsatzdienste",
          tasks: 12,
          progress: 72,
          color: "primary",
        },
        {
          title: "Übungsdienste",
          tasks: 3,
          progress: 48,
          color: "success",
        },
        {
          title: "Wachbesetzungen",
          tasks: 2,
          progress: 15,
          color: "error",
        },
      ],

      // Radial chart
      series: [67],
      chartOptions: {
        chart: {
          height: 180,
          type: "radialBar",
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -100,
            endAngle: 100,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
              },
              value: {
                fontSize: "18px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["Median Ratio"],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 1.5rem;
}
</style>
