<template>
  <div>
    <v-card>
      <v-card-title v-if="!helpers.dataIsLoading"
        >Offene Vorgänge<v-spacer></v-spacer>

        <div class="mr-2">
          <v-btn-toggle v-model="displayMode" dense mandatory>
            <v-btn outlined icon value="list">
              <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                >mdi-view-sequential-outline</v-icon
              >
            </v-btn>
            <v-btn depressed outlined icon value="table">
              <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                >mdi-table</v-icon
              >
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-card-title>
      <div v-if="displayMode === 'list'">
        <v-skeleton-loader
          v-if="helpers.dataIsLoading"
          elevation="2"
          type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
        ></v-skeleton-loader>
        <v-data-iterator
          v-else
          :items="openTasks"
          no-data-text="Keine Vorgänge bevorstehend"
        >
          <template v-slot:default="props">
            <v-card flat>
              <v-list>
                <v-list-item
                  v-for="task in props.items"
                  :key="task.id"
                  link
                  dense
                  :to="task.route"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="subtitle-1 font-weight-medium mb-1"
                    >
                      <span>{{ task.title }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <p style="line-height: 1.6" class="mb-0">
                        <v-icon small left>mdi-circle</v-icon
                        >{{ task.type.title }}
                        <br />
                        <v-icon small left>mdi-circle</v-icon>{{ task.status }}
                        <br />
                        <v-icon small left>mdi-sand-timer</v-icon
                        >{{ task.dueDate.timestamp }}<br />
                      </p>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="error" depressed>
                      {{ task.actionTitle ? task.actionTitle : "Fortsetzen" }}
                      <v-icon right> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </template>
          <template v-slot:no-data>
            <div
              style="
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <p class="subtitle-1">Keine Vorgänge vorhanden.</p>
            </div>
          </template>
        </v-data-iterator>
      </div>
      <div v-else>
        <v-skeleton-loader
          v-if="helpers.dataIsLoading"
          elevation="2"
          type="table"
        ></v-skeleton-loader>
        <v-data-table v-else :items="openTasks" :headers="headers">
          <!-- <template v-slot:[`item.type.title`]="{ item }"></template> -->
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn depressed small color="error">{{ item.actionTitle }}</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
// import { SCHEDULING } from "@/store/modules.js";
// import { GET_EVENTS } from "@/store/action-types.js";
export default {
  name: "self-service-open-tasks-card",
  props: {},
  components: {},
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
      displayMode: "table",
      headers: [
        { text: "Titel", value: "title" },
        { text: "Typ", value: "type.title" },
        { text: "Status", value: "status" },
        { text: "Fälligkeit", value: "dueDate.timestamp" },
        { text: "Aktion", value: "action" },
      ],
    };
  },
  computed: {
    openTasks() {
      return [
        {
          id: "1",
          title: "Titel",
          dueDate: { timestamp: "XX.XX.2024 12:00" },
          status: "status",
          type: { title: "report" },
          actionTitle: "Aktion",
        },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //   this.$store.dispatch(`${SCHEDULING}${GET_EVENTS}`, {
      //     organizationId: this.$route.params.organizationId,
      //     withStatus: true,
      //     query: {
      //       onlyOwn: true,
      //       ownUserGroup: this.$store.state.organization.membership.group.id,
      //     },
      //     // withStatus: this.$store.getters["organization/checkPermissionByID"](
      //     //   `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`
      //     // )
      //     //   ? true
      //     //   : false,
      //     paginationLimit: 3,
      //   });
    },

    statusIcon(item = "") {
      switch (item) {
        case "0":
          return "mdi-help";
        case "1":
          return "mdi-check";
        case "2":
          return "mdi-close";
        case "3":
          return "mdi-minus";
        case "4":
          return "mdi-airplane";
        case "":
          return "mdi-alert-outline";
        default:
          return "";
      }
    },
    statusColorAsHex(item = "") {
      switch (item) {
        case "0":
          return "#455a64";
        case "1":
          return "#4caf50";
        case "2":
          return "#ff5252";
        case "3":
          return "#fb8c00";
        case "4":
          return "#2196f3";
        case "":
          return "#455a64";
        default:
          return "";
      }
    },
  },
};
</script>
