<template>
  <div>
    <v-card color="blue-grey darken-4" dark>
      <v-card-title
        >Willkommen John Doe!
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="blue-grey lighten-4"
          light
          :class="
            $vuetify.theme.dark
              ? 'blue-grey--text text--darken-4'
              : 'blue-grey--text--text text--darken-4'
          "
          ><v-icon left>mdi-pencil</v-icon>Profil bearbeiten</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-col cols="12" lg="10" md="10" sm="12" class="px-2">
          <v-row class="match-height">
            <v-col
              v-for="(item, index) in quickActions"
              :key="index"
              cols="6"
              lg="3"
              md="3"
              sm="3"
              style="padding: 6px !important"
            >
              <v-card
                :color="
                  $vuetify.theme.dark
                    ? `${item.color} lighten-4`
                    : `${item.color} lighten-5`
                "
                class="d-flex align-center rounded-lg"
                :disabled="item.disabled"
                height="70"
                flat
                @click="item.action"
              >
                <!-- @click="item.action" -->
                <v-row>
                  <v-col cols="12" md="12">
                    <v-list-item three-line class="text-center mt-1">
                      <v-list-item-content>
                        <v-icon
                          :color="
                            $vuetify.theme.dark
                              ? `${item.color} darken-4`
                              : `${item.color} darken-4`
                          "
                          >{{ item.icon }}</v-icon
                        >
                        <v-list-item-subtitle
                          class="mt-n2 font-weight-medium"
                          :class="
                            $vuetify.theme.dark
                              ? `${item.color}--text text--darken-4`
                              : `${item.color}--text text--darken-4`
                          "
                          >{{ item.text }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { SCHEDULING } from "@/store/modules.js";
// import { GET_EVENTS } from "@/store/action-types.js";
export default {
  name: "self-service-quick-actions-card",
  props: {},
  components: {},
  computed: {
    quickActions() {
      //   return this.$store.state.selfService.quickActions;
      return [
        {
          id: "1",
          text: "Abmeldung erstellen",
          icon: "mdi-airplane-plus",
          color: "blue-grey",
          action: () => {
            this.$router.push({
              name: "availability-leaves-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
        {
          id: "2",
          text: "Stunden melden",
          icon: "mdi-timer-plus",
          color: "blue-grey",
          disabled: true,
          action: () => {
            this.$router.push({
              name: "availability-leaves-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
        {
          id: "3",
          text: "Auslagenersatz erstellen",
          icon: "mdi-invoice-text-plus",
          color: "blue-grey",
          disabled: true,
          action: () => {
            this.$router.push({
              name: "availability-leaves-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
        {
          id: "4",
          text: "Sonstige Aktionen",
          icon: "mdi-dots-horizontal-circle",
          color: "blue-grey",
          disabled: false,
          action: () => {
            // this.toggleOtherActionsDialog()
          },
        },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //   this.$store.dispatch(`${SCHEDULING}${GET_EVENTS}`, {
      //     organizationId: this.$route.params.organizationId,
      //     withStatus: true,
      //     query: {
      //       onlyOwn: true,
      //       ownUserGroup: this.$store.state.organization.membership.group.id,
      //     },
      //     // withStatus: this.$store.getters["organization/checkPermissionByID"](
      //     //   `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`
      //     // )
      //     //   ? true
      //     //   : false,
      //     paginationLimit: 3,
      //   });
    },
  },
};
</script>
