var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"color":"blue-grey darken-4","dark":""}},[_c('v-card-title',[_vm._v("Willkommen John Doe! "),_c('v-spacer'),_c('v-btn',{class:_vm.$vuetify.theme.dark
            ? 'blue-grey--text text--darken-4'
            : 'blue-grey--text--text text--darken-4',attrs:{"depressed":"","color":"blue-grey lighten-4","light":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Profil bearbeiten")],1)],1),_c('v-card-text',[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","lg":"10","md":"10","sm":"12"}},[_c('v-row',{staticClass:"match-height"},_vm._l((_vm.quickActions),function(item,index){return _c('v-col',{key:index,staticStyle:{"padding":"6px !important"},attrs:{"cols":"6","lg":"3","md":"3","sm":"3"}},[_c('v-card',{staticClass:"d-flex align-center rounded-lg",attrs:{"color":_vm.$vuetify.theme.dark
                  ? `${item.color} lighten-4`
                  : `${item.color} lighten-5`,"disabled":item.disabled,"height":"70","flat":""},on:{"click":item.action}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-list-item',{staticClass:"text-center mt-1",attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark
                            ? `${item.color} darken-4`
                            : `${item.color} darken-4`}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-subtitle',{staticClass:"mt-n2 font-weight-medium",class:_vm.$vuetify.theme.dark
                            ? `${item.color}--text text--darken-4`
                            : `${item.color}--text text--darken-4`},[_vm._v(_vm._s(item.text))])],1)],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }